import { ACC_API } from '../constants/config';

export default async function getAppsOverview(app) {
	const response = await fetch(`${ACC_API}/app/${app}/plan`, {
		method: 'GET',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
		},
	});
	let data;
	try {
		data = await response.json();
	} catch (error) {
		data = {
			message: 'Something went Wrong!',
		};
	}

	if (response.status >= 400) {
		throw new Error(JSON.stringify(data));
	}

	const { body } = data;
	return { ...body };
}
