/* eslint-disable camelcase */
import React, { Suspense, useEffect, useReducer } from 'react';
import { Divider, Icon, Card, Button } from 'antd';
import { css } from 'emotion';
import { useAuth0 } from '@auth0/auth0-react';

import AppCard from './components/AppCard/AppCard';
import SelectSource from './components/SelectSource';
import SelectDestination from './components/SelectDestination';
import { userReducer, sourceReducer, destinationReducer } from './reducers';
import { initialUser } from './reducers/userReducer';
import { USER, SOURCE } from './constants';
import {
	createUserIfNew,
	getUser,
	injectAuthTokenHeaderIntoFetchGlobally,
	showPaidWall,
} from './utils';
// eslint-disable-next-line import/no-cycle
import { EmailVerificationUI } from './components/Navbar/UserProfile';
import { initialSource } from './reducers/sourceReducer';
import { initialDestination } from './reducers/destinationReducer';
import AppFooter from './components/AppFooter';
import Payment from './components/Payment';
import HelpChat from './components/HelpChat';

const Container = React.lazy(() => import('./components/Container'));

const sourceOptions = [
	{
		name: 'JSON/CSV File',
		value: 'SourceFile',
	},
	{
		name: 'Elasticsearch',
		value: 'SourceES',
	},
	{
		name: 'SQL',
		value: 'SourceSQL',
	},
	{
		name: 'Shopify',
		value: 'SourceShopify',
	},
];

const destinationOptions = [
	{
		name: 'Appbase.io App',
		value: 'AppbaseApp',
	},
	{
		name: 'Appbase.io Cluster',
		value: 'AppbaseCluster',
	},
];

const buttonStyle = css`
	@media (max-width: 768px) {
		margin-top: 23px;
		padding: 0px 10px;
		max-width: 140px;
	}
`;

export const UserContext = React.createContext(null);

const App = ({ embed, initSource, initDestination }) => {
	const {
		isAuthenticated,
		getAccessTokenSilently,
		getIdTokenClaims,
		loginWithRedirect,
		logout,
	} = useAuth0();
	const [user, dispatchUser] = useReducer(userReducer, initialUser);
	const [userLoading, setUserLoading] = React.useState(true);
	const [source, dispatchSource] = useReducer(sourceReducer, initSource || initialSource);
	const [destination, dispatchDestination] = useReducer(
		destinationReducer,
		initDestination || initialDestination,
	);
	useEffect(() => {
		try {
			dispatchUser({ type: USER.LOAD });
			getUser()
				.then(({ user: userData }) => {
					setUserLoading(false);
					dispatchUser({ type: USER.LOAD_SUCCESS, payload: userData });
				})
				.catch(e => {
					setUserLoading(false);
					dispatchUser({ type: USER.LOAD_ERROR, error: e.message || e });
				});
		} catch (e) {
			setUserLoading(false);
			dispatchUser({ type: USER.LOAD_ERROR, error: e });
		}
	}, []);

	const getAuth0AccessToken = async () => {
		try {
			setUserLoading(true);
			dispatchUser({ type: USER.LOAD });
			let accessToken;
			let token_id;

			if (!accessToken) {
				accessToken = await getAccessTokenSilently({
					cacheMode: 'off',
				}); // https://github.com/auth0/auth0-spa-js/issues/693#issuecomment-757125378
			}
			if (!token_id) {
				token_id = (await getIdTokenClaims()).__raw;
			}
			if (accessToken) {
				window.localStorage.setItem('AUTH_0_ACCESS_TOKEN', accessToken);
				injectAuthTokenHeaderIntoFetchGlobally();

				// generic request to check whether user exists in appbasde DB,
				// if not then we automatically sign him in based on auth0 token_id
				await createUserIfNew(token_id);

				const res = await getUser();
				if (res.action && res.action.email_verification) {
					setTimeout(() => {
						getAuth0AccessToken();
					}, 10000);
					if (res.error) {
						dispatchUser({ type: USER.LOAD_ERROR, error: res });
					}
					return;
				}

				const { user: userObj } = res;
				dispatchUser({ type: USER.LOAD_SUCCESS, payload: userObj });
			}
		} catch (error) {
			console.error('🚀 Error getting user from ACCAPI', error);
		} finally {
			setUserLoading(false);
		}
	};

	useEffect(() => {
		if (
			isAuthenticated &&
			!user.data &&
			!(
				user.error &&
				user.error.actual &&
				user.error.actual.action &&
				user.error.actual.action.email_verification
			)
		) {
			getAuth0AccessToken();
		}
	}, [isAuthenticated]);
	const handleLogout = () => {
		window.localStorage.removeItem('AUTH_0_ACCESS_TOKEN');

		try {
			if (isAuthenticated) {
				logout({
					logoutParams: { returnTo: window.location.origin },
				});
			}
		} catch (error) {
			console.log('Error logging out...', error);
		}
	};
	return (
		<React.Fragment>
			<UserContext.Provider value={user}>
				<Suspense fallback="Loading">
					<Container embed={embed}>
						<AppCard
							title="Select Source"
							options={sourceOptions}
							extra={
								<Button
									onClick={() => {
										dispatchSource({
											type: SOURCE.UPDATE,
											payload: {
												subType: 'url',
												useBulk: true,
												uri:
													'https://raw.githubusercontent.com/appbaseio/cdn/dev/appbase/ecommerce_data.json',
												extraType: 'SourceFile',
												type: 'json',
											},
										});
									}}
									className={buttonStyle}
								>
									Load Sample Data
								</Button>
							}
							defaultSelected={source && source.extraType}
						>
							{({ selectedValue }) => (
								<SelectSource
									sourceContext={{ dispatch: dispatchSource, value: source }}
									destinationContext={{
										dispatch: dispatchDestination,
										value: destination,
									}}
									selectedValue={selectedValue}
								/>
							)}
						</AppCard>
						{source && source.uri && !userLoading && !user.data && (
							<>
								<Divider>
									<Icon
										style={{
											background: '#1890ff',
											color: '#ffffff',
											padding: 5,
											borderRadius: '50%',
											transform: 'rotateZ(90deg)',
										}}
										type="swap"
									/>
								</Divider>
								<Card
									style={{
										textAlign: 'center',
									}}
								>
									{user &&
									user.error &&
									user.error.action &&
									user.error.action.email_verification ? (
										<EmailVerificationUI handleLogout={handleLogout} />
									) : (
										<Button type="primary" onClick={() => loginWithRedirect()}>
											Login
										</Button>
									)}
								</Card>
							</>
						)}
						{((source && source.uri) ||
							(destination && (destination.cluster || destination.uri))) &&
							user.data && (
								<>
									<Divider>
										<Icon
											style={{
												background: '#1890ff',
												color: '#ffffff',
												padding: 5,
												borderRadius: '50%',
												transform: 'rotateZ(90deg)',
											}}
											type="swap"
										/>
									</Divider>
									<AppCard
										title="Select Destination"
										defaultSelected={destination && destination.type}
										options={destinationOptions}
									>
										{({ selectedValue }) => (
											<SelectDestination
												destinationContext={{
													dispatch: dispatchDestination,
													value: destination,
												}}
												apps={Object.keys(user.data.apps)}
												selectedValue={selectedValue}
											/>
										)}
									</AppCard>
								</>
							)}

						{destination &&
							destination.uri &&
							source &&
							source.size &&
							showPaidWall({ currentPlan: destination.tier, size: source.size }) && (
								<>
									<Divider>
										<Icon
											style={{
												background: '#1890ff',
												color: '#ffffff',
												padding: 5,
												borderRadius: '50%',
												transform: 'rotateZ(90deg)',
											}}
											type="swap"
										/>
									</Divider>
									<Card title="Select Plan">
										<Payment
											source={source}
											destination={destination}
											updateDestiantion={dispatchDestination}
										/>
									</Card>
								</>
							)}
					</Container>

					<AppFooter
						source={source}
						dispatchSource={dispatchSource}
						dispatchDestination={dispatchDestination}
						email={user && user.data && user.data.email}
						destination={destination}
						embed={embed}
					/>
				</Suspense>
			</UserContext.Provider>
			{!embed && <HelpChat />}
		</React.Fragment>
	);
};

export default App;
