import { getESHeaders, parseUrl } from '../utils';

export const settings = {
	'index.max_ngram_diff': 10,
	analysis: {
		analyzer: {
			autosuggest_analyzer: {
				filter: ['lowercase', 'asciifolding', 'autosuggest_filter'],
				tokenizer: 'standard',
				type: 'custom',
			},
			ngram_analyzer: {
				filter: ['lowercase', 'asciifolding', 'ngram_filter'],
				tokenizer: 'standard',
				type: 'custom',
			},
		},
		filter: {
			autosuggest_filter: {
				max_gram: '20',
				min_gram: '1',
				token_chars: ['letter', 'digit', 'punctuation', 'symbol'],
				type: 'edge_ngram',
			},
			ngram_filter: {
				max_gram: '9',
				min_gram: '2',
				token_chars: ['letter', 'digit', 'punctuation', 'symbol'],
				type: 'ngram',
			},
		},
	},
};

/**
 *
 * @param {string} url
 * @param {string} app
 */
export default async function(url, app) {
	try {
		const { url: originalUrl } = parseUrl(url);
		const res = await fetch(`${originalUrl}/${app}/_settings`, {
			method: 'PUT',
			headers: getESHeaders(url),
			body: JSON.stringify(settings),
		});

		const json = await res.json();
		return json;
	} catch (error) {
		throw error;
	}
}
