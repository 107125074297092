import React, { Suspense } from 'react';
import { Skeleton } from 'antd';
import { string, object } from 'prop-types';

const getSourceComponent = file => ({
	SourceComponent: React.lazy(() => import(`./${file}`)),
});

const SelectSource = ({ selectedValue, sourceContext, destinationContext }) => {
	const { SourceComponent } = getSourceComponent(selectedValue);

	return (
		<Suspense fallback={<Skeleton />}>
			<SourceComponent
				sourceContext={sourceContext}
				destinationContext={destinationContext}
			/>
		</Suspense>
	);
};

SelectSource.propTypes = {
	destinationContext: object.isRequired,
	selectedValue: string.isRequired,
	sourceContext: object.isRequired,
};

export default SelectSource;
