import { css } from 'emotion';
import { mediumWeight, titleColor, themeColor } from './theme';

export const layoutStyle = css`
	padding-left: 40px !important;
	padding-right: 40px !important;
	@media (max-width: 768px) {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
`;

export const formContainer = css`
	max-width: 960px;
	margin: 0 auto !important;
	p {
		margin: 5px 0;
		font-weight: ${mediumWeight};
		color: ${titleColor};
	}
	.mt-10 {
		margin-top: 10px;
	}
	.mt-20 {
		margin-top: 20px;
	}
	.mr-10 {
		margin-right: 10px;
	}
	.ml-5 {
		margin-left: 5px;
	}
`;

export const formWrapper = css`
	max-width: 800px;
`;

export const codeSection = css`
	background: #f5f5f5;
	border-radius: 4px;
	margin: 10px auto;
	padding: 0px 10px;
`;

export const code = css`
	background: #f9f2f4;
	color: #c7254e;
	padding: 2px 4px;
	border-radius: 4px;
`;

export const fileFormat = css`
	color: ${themeColor};
	float: right;
	display: block;
	margin-top: 15px;
`;
