import React, { Component } from 'react';
import { css } from 'emotion';
import { node, string } from 'prop-types';
import { Icon } from 'antd';

const blankLink = css`
	display: flex;
	align-items: center;
	color: inherit;
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 19px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	margin: 10px 0;
	@media (max-width: 1024px) {
		margin: 25px 3px;
	}

	&:hover {
		color: rgba(255, 255, 255, 0.5);
	}
`;

const iconStyles = css`
	margin-left: 5px;
	font-size: 0.75rem;
`;

export default class ClickToShow extends Component {
	state = {
		visible: false,
	};

	render() {
		const { children, label = 'All Features', hideLabel = 'Hide Features' } = this.props;
		const { visible } = this.state;

		if (visible) {
			return (
				<>
					{children}
					<a
						className={blankLink}
						onClick={() => {
							this.setState({ visible: false });
						}}
					>
						{hideLabel}
						<Icon type="up" className={iconStyles} />
					</a>
				</>
			);
		}
		return (
			<a
				className={blankLink}
				onClick={() => {
					this.setState({ visible: true });
				}}
			>
				{label} <Icon type="down" className={iconStyles} />
			</a>
		);
	}
}

ClickToShow.propTypes = {
	children: node,
	label: string,
	hideLabel: string,
};
