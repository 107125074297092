import { SOURCE } from '../constants';
import { sourceStorageValue } from '../utils';

const localValue = sourceStorageValue();

export const initialSource = localValue ? JSON.parse(localValue) : null;

function sourceReducer(state = initialSource, action) {
	switch (action.type) {
		case SOURCE.UPDATE: {
			return {
				...action.payload,
			};
		}
		case SOURCE.RESET: {
			return null;
		}
		default:
			return state;
	}
}

export default sourceReducer;
