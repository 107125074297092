/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React from 'react';
import { Avatar, Dropdown, Menu, Icon, Button, Spin, Alert, Popover } from 'antd';
import { func } from 'prop-types';

import { useAuth0 } from '@auth0/auth0-react';
import { css } from 'emotion';
import { themeColor, lightThemeColor } from '../../styles/theme';
import { UserContext } from '../../App';
import { parseJwt } from '../../utils';

export const EmailVerificationUI = ({ handleLogout }) => {
	return (
		<Alert
			message={
				<div
					className={css`
						text-align: left;
					`}
				>
					An e-mail has been sent to &nbsp;
					<b>
						{
							parseJwt(window.localStorage.getItem('AUTH_0_ACCESS_TOKEN'))[
								'https://reactivesearch.io-email'
							]
						}
					</b>
					&nbsp; address for verification!
				</div>
			}
			description={
				<div
					className={css`
						text-align: left;
					`}
				>
					Once you&apos;ve verified, this page should auto-refresh within 30s 
					<div
						className={css`
							display: flex;
							justify-content: space-between;
						`}
					>
						<Button
							className={css`
								padding-left: 0;
							`}
							onClick={() => window.Intercom('show')}
							type="link"
						>
							Contact Support{' '}
						</Button>
						<Button
							className={css`
								padding-left: 0;
								padding-right: 0;
							`}
							onClick={() => {
								handleLogout();
							}}
							type="link"
						>
							Logout
						</Button>
					</div>
				</div>
			}
		/>
	);
};

EmailVerificationUI.propTypes = {
	handleLogout: func.isRequired,
};

const UserProfile = () => {
	const { isLoading, data: user, error: userReducerError } = React.useContext(UserContext);
	const { isAuthenticated, loginWithRedirect, logout, isLoading: isLoadingAuth0 } = useAuth0();

	const handleLogout = () => {
		window.localStorage.removeItem('AUTH_0_ACCESS_TOKEN');

		try {
			if (isAuthenticated) {
				logout({
					logoutParams: { returnTo: window.location.origin },
				});
			}
		} catch (error) {
			console.log('Error logging out...', error);
		}
	};

	if (userReducerError && userReducerError.action && userReducerError.action.email_verification) {
		return (
			<Popover content={<EmailVerificationUI handleLogout={handleLogout} />}>
				<span
					role="img"
					aria-label="Warning"
					className={css`
						font-size: 1.5rem;
						cursor: default;
					`}
				>
					⚠️
				</span>
			</Popover>
		);
	}

	if (isLoading || isLoadingAuth0) {
		return <Spin spinning />;
	}

	if (!isAuthenticated) {
		const handleLoginWithRedirect = () => {
			loginWithRedirect();
		};

		return (
			<Button type="primary" onClick={handleLoginWithRedirect}>
				Login
			</Button>
		);
	}

	return (
		<Dropdown
			overlay={
				<Menu>
					<Menu.Item key="0" onClick={handleLogout}>
						Logout
					</Menu.Item>
				</Menu>
			}
			trigger={['click']}
			overlayStyle={{ minWidth: 200 }}
		>
			<div style={{ cursor: 'pointer' }}>
				<Avatar
					style={{ marginRight: 8, backgroundColor: lightThemeColor, color: themeColor }}
					size="large"
					src={user && user.picture}
				>
					{user
						? user.name
							? user.name.charAt(0).toUpperCase()
							: user.email.charAt(0).toUpperCase()
						: null}
				</Avatar>
				{user && (user.name || user.email)}
				<Icon type="down" style={{ marginLeft: 8 }} />
			</div>
		</Dropdown>
	);
};
export default UserProfile;
