import { ACC_API } from '../constants/config';

export async function createSubscription({ app, token, plan }) {
	try {
		const res = await fetch(`${ACC_API}/app/${app}/subscription`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				token,
				plan,
			}),
		});

		if (res.status >= 400) {
			throw new Error();
		}
		const json = await res.json();
		return json;
	} catch (error) {
		throw error;
	}
}
