// Colors
export const titleColor = '#262626';
export const lightThemeColor = '#e6f7ff';
export const themeColor = '#1890ff';
export const logoColor = '#595959';

// Shadow
export const greyShadow = '#bfbfbf';

// Size
export const fontSizeTitle = '2.75rem';
export const fontSizeLogo = '1.6em';
export const fontSizeRegular = '1rem';

// Weights
export const regularWeight = '400';
export const mediumWeight = '500';
export const boldWeight = '700';
