import { updateSettings } from '../api/update-settings';
import getCluster from '../api/get-cluster';
import getClusterStatus from '../api/get-cluster-status';

export default async function applyClusterSettings(cluster, index) {
	let clusters = [];
	try {
		const res = await getCluster();
		const { clusters: allClusters } = res;
		const activeClusters = allClusters.filter(item => item.status === 'active');
		clusters = activeClusters;

		const clusterId = clusters.find(item => item.name === cluster).id;
		const { deployment, cluster: clusterDetails } = await getClusterStatus(clusterId);

		const { es_version } = clusterDetails;
		const elasticSearchDetails = deployment.elasticsearch;
		const { password, url, username } = elasticSearchDetails;
		const { host } = new URL(url);

		const uri = `https://${username}:${password}@${host}`;
		const destinationUri = `${uri}/${index}`;

		return {
			index,
			cluster,
			type: 'AppbaseCluster',
			uri: destinationUri,
			tier: 'paid',
			es_version,
			url: uri,
		};
	} catch (e) {
		console.error(e);
		throw new Error(e);
	}
}
