import { getESHeaders, parseUrl } from '../utils';

/**
 *
 * @param {string} url
 * @param {string} app
 */
export default async function(url, app) {
	const { url: originalUrl } = parseUrl(url);
	try {
		const res = await fetch(`${originalUrl}/${app}/_settings`, {
			method: 'GET',
			headers: getESHeaders(url),
		});

		const json = await res.json();
		return json;
	} catch (error) {
		throw error;
	}
}
