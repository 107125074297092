import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import App from './App';

import { getSearchParams } from './utils';
import applySettings from './utils/applyAppSettings';
import applyClusterSettings from './utils/applyClusterSettings';

const AppWrapper = () => {
	const searchParams = getSearchParams(window.location.search);

	const embed = searchParams.embed && JSON.parse(searchParams.embed);
	let source = searchParams.source && JSON.parse(searchParams.source);
	const destinationParams = searchParams.destination && JSON.parse(searchParams.destination);
	const [destination, setDestination] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const { origin, appname, url } = searchParams;

	if (origin === 'dejavu') {
		source = {
			indexName: appname || '',
			type: 'elasticsearch',
			clusterURL: url || '',
		};
	}

	useEffect(() => {
		if (destinationParams && destinationParams.type === 'App') {
			setLoading(true);
			const { app } = destinationParams;
			applySettings(app)
				.then(data => {
					setDestination(data);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		} else if (
			destinationParams &&
			destinationParams.type === 'Cluster' &&
			destinationParams.cluster &&
			destinationParams.index &&
			destinationParams.clusterType !== 'self-hosted'
		) {
			setLoading(true);
			const { index, cluster } = destinationParams;
			applyClusterSettings(cluster, index)
				.then(data => {
					setDestination(data);
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		} else if (
			destinationParams &&
			destinationParams.type === 'Cluster' &&
			destinationParams.cluster
		) {
			setLoading(true);
			const { cluster, clusterType, index } = destinationParams;
			const uri = index ? `${cluster}/${index}` : undefined;
			const timeoutId = setTimeout(() => {
				setDestination({
					cluster,
					clusterType,
					tier: 'paid',
					index,
					uri,
					type: 'AppbaseCluster',
				});

				setLoading(false);
			}, 1000);

			return () => clearTimeout(timeoutId);
		}
	}, []);

	if (source) {
		switch (source.type) {
			case 'elasticsearch': {
				source = {
					clusterURL: source.clusterURL,
					indexName: source.indexName,
					uri: `${source.clusterURL}/${source.indexName}`,
					type: 'elasticsearch',
					extraType: 'SourceES',
				};
				break;
			}
			case 'url': {
				source = {
					uri: source.uri,
					extraType: 'SourceFile',
					subType: 'url',
					type: source.extension,
				};
				break;
			}
			case 'upload': {
				source = {
					uri: source.uri,
					name: source.name,
					size: source.size,
					type: source.extension,
					extraType: 'SourceFile',
					subType: 'upload',
				};
				break;
			}
			default: {
				source = {};
			}
		}
	}

	return loading ? (
		<div style={{ maxWidth: '80%', margin: '20px auto' }}>
			<h2>Preparing app for Import. This may take few seconds.</h2>
			<Skeleton />
		</div>
	) : (
		<App embed={embed} initSource={source} initDestination={destination} />
	);
};

export default AppWrapper;
