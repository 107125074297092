import React from 'react';
import { Card, Button, Dropdown, Menu, Icon } from 'antd';
import { array, func, string } from 'prop-types';
import { css } from 'emotion';

const ButtonGroup = Button.Group;

const leftSpacing = css`
	margin-left: 20px;
`;

const buttonGroupStyle = css`
	display: block;
	margin: 20px 0px;
	max-width: 140px;
`;

const AppCard = ({ options, title, children, defaultSelected, extra }) => {
	const [selectedValue, changeValue] = React.useState(defaultSelected || options[0].value);

	const setValue = value => {
		changeValue(value);
	};

	const handleMenuClick = e => {
		setValue(e.key);
	};

	const renderButtons = () => {
		if (window.innerWidth < 768) {
			const menu = (
				<Menu onClick={handleMenuClick}>
					{options.map(tab => (
						<Menu.Item key={tab.value}>{tab.name}</Menu.Item>
					))}
				</Menu>
			);

			const { name: selectedName } = options.find(item => item.value === selectedValue);

			return (
				<Dropdown overlay={menu} className={buttonGroupStyle}>
					<Button>
						{selectedName} <Icon type="down" />
					</Button>
				</Dropdown>
			);
		}
		return (
			<ButtonGroup className={leftSpacing}>
				{options.map(tab => (
					<Button
						type={tab.value === selectedValue ? 'primary' : 'default'}
						key={tab.value}
						onClick={() => setValue(tab.value)}
					>
						{tab.name}
					</Button>
				))}
			</ButtonGroup>
		);
	};

	return (
		<Card
			title={
				<div>
					{title}
					{options && options.length && renderButtons()}
				</div>
			}
			extra={extra || null}
			hoverable
		>
			{children({ selectedValue })}
		</Card>
	);
};

AppCard.propTypes = {
	options: array,
	title: string,
	children: func.isRequired,
};

AppCard.defaultProps = {
	options: [],
	title: '',
};

export default AppCard;
