import React from 'react';
import { Button, Icon, notification, Layout } from 'antd';
import { object, string } from 'prop-types';
import { css, cx } from 'emotion';
import { layoutStyle } from '../../styles/common';
import { greyShadow } from '../../styles/theme';

import importData from '../../api/import';
import verifyData from '../../api/verify';
import bulkRequest from '../../api/bulk-request';

import { DESTINATION, SOURCE } from '../../constants';
import { showPaidWall } from '../../utils';
import { updateSettings } from '../../api/update-settings';

const { Footer } = Layout;

const footerStyles = css`
	background-color: white !important;
	box-shadow: 0 -1px 6px ${greyShadow};
	height: 60px;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
	position: sticky;
	bottom: 0;
	z-index: 99;
`;

const AppFooter = ({ source, destination, email, dispatchSource, dispatchDestination, embed }) => {
	const [loading, setLoading] = React.useState(false);

	const dataPresent = source && source.uri && destination && destination.uri;

	const paidWall =
		source &&
		destination &&
		destination.uri &&
		showPaidWall({ currentPlan: destination.tier, size: source.size });

	const handleImport = async () => {
		setLoading(true);
		let isEmbedAppReady = false;

		if (embed) {
			if (destination.type === 'AppbaseCluster' || destination.type === 'AppbaseApp') {
				try {
					const response = await updateSettings(
						destination.url,
						destination.index,
						destination.es_version,
						true,
					);

					if (response && response.isUpdated) {
						isEmbedAppReady = true;
					}
				} catch (e) {
					console.error(e.message);
				}
			}
		}

		if (embed && !isEmbedAppReady) {
			notification.error({
				title: 'Error while preparing your app for import',
			});
			return;
		}

		if (source.useBulk) {
			try {
				const bulkResponse = await bulkRequest(destination.uri, source.uri);

				if (!bulkResponse.errors) {
					notification.success({
						duration: 10,
						message: 'Import successfully',
						description:
							'Sample data has been successfully imported to the destination index.',
					});
					localStorage.removeItem('source');
					dispatchDestination({
						type: DESTINATION.RESET,
					});
					dispatchSource({
						type: SOURCE.RESET,
					});
				}
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
			return;
		}

		const params = {
			src_uri: source.uri,
			destination: destination.uri,
			email,
			src_type: source.type,
		};
		if (source.filter) {
			params.src_filter = source.filter;
		}

		let isVerified = false;

		try {
			const verifiedData = await verifyData(params);
			if (verifiedData.success) {
				isVerified = true;
			}
		} catch (e) {
			isVerified = false;
		}

		if (isVerified) {
			try {
				const importResponse = await importData(params);
				if (importResponse.success) {
					notification.success({
						duration: 10,
						message: 'Import successfully queued',
						description:
							'Your import is successfully queued. It should take a few minutes to process.',
					});
					localStorage.removeItem('source');
					dispatchDestination({
						type: DESTINATION.RESET,
					});
					dispatchSource({
						type: SOURCE.RESET,
					});
				}
				setLoading(false);
			} catch (e) {
				console.error(e);
				setLoading(false);
			}
		} else {
			notification.error({
				message: 'Could not verify the connection',
			});
			setLoading(false);
		}
	};

	return (
		<Footer className={cx(footerStyles, layoutStyle)}>
			<Button
				loading={loading}
				onClick={handleImport}
				type="primary"
				disabled={dataPresent ? paidWall : true}
			>
				Start Import
				<Icon type="import" />
			</Button>
		</Footer>
	);
};

AppFooter.propTypes = {
	source: object,
	destination: object,
	email: string,
};

AppFooter.defaultProps = {
	source: null,
	destination: null,
	email: '',
};

export default AppFooter;
