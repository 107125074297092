import React, { useEffect } from 'react';
import { Icon, notification } from 'antd';
import { css } from 'emotion';
import Stripe from 'react-stripe-checkout';
import NewPricingCard from './NewPricingCard';
import theme from './theme';
import { createSubscription } from '../../api/app-subscription';
import { DESTINATION } from '../../constants';
import { STRIPE_KEY } from '../../constants/config';

const iconStyles = css`
	font-size: 0.6rem;
	margin-right: 5px;
`;

const listCaption = css`
	margin: 15px 0 5px;
	color: rgba(255, 255, 255, 0.8);
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 17px;
	text-align: left;
	text-decoration: none !important;
	text-transform: uppercase;
`;

const container = css`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 40px;
	max-width: 800px;
	margin: 0 auto;
	@media (max-width: 768px) {
		grid-template-columns: auto;
	}
`;

const linkStyle = css`
	font-size: 1.1rem;
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	font-weight: 700;
	border-bottom-width: 2px;
	border-bottom-style: dashed;
`;

const errorContainer = css`
	max-width: 800px;
	color: #f5222d;
	padding: 10px 0;
	font-size: 1rem;
	font-weight: bold;
	margin: 0 auto;
`;

const CheckList = ({ list }) =>
	list.map(item => (
		<li key={item}>
			<Icon className={iconStyles} type="check" />
			{item}
		</li>
	));

const Payment = ({ destination, updateDestiantion }) => {
	const stripeKey = STRIPE_KEY;
	const [isPaying, setPaymentLoader] = React.useState(false);
	const [error, setError] = React.useState('');

	useEffect(() => {
		setError('');
		setPaymentLoader(false);
	}, [destination]);

	const handleToken = async (token, plan) => {
		try {
			setPaymentLoader(true);
			const response = await createSubscription({ token, plan, app: destination.index });
			if (
				response &&
				response.subscription_details &&
				response.subscription_details.status === 'active'
			) {
				notification.success({
					message: response.message,
				});
				setPaymentLoader(false);
				updateDestiantion({
					type: DESTINATION.UPDATE,
					payload: {
						...destination,
						tier: 'paid',
					},
				});
			}
		} catch (e) {
			const errorMessage = e.message || 'Could not upgrade your subscription';
			notification.error({
				message: errorMessage,
			});
			setError(errorMessage);
			setPaymentLoader(false);
		}
	};
	return isPaying ? (
		<h2 style={{ textAlign: 'center' }}>Upgrading your subscription</h2>
	) : (
		<React.Fragment>
			{error ? <div className={errorContainer}>{error}</div> : null}
			<div className={container}>
				<NewPricingCard
					style={{ backgroundColor: theme.badge.blue }}
					name="Bootstrap"
					price="$29"
					renderLink={price => (
						<Stripe
							name="Appbase.io Bootstrap Plan"
							amount={price * 100}
							token={token => handleToken(token, 'bootstrap-monthly')}
							stripeKey={stripeKey}
						>
							<span className={linkStyle}>Upgrade</span>
						</Stripe>
					)}
					pricingList={['50K Records', '1M API Calls / mo', '30 Queries / sec']}
				>
					<div className={listCaption}>Features</div>
					<CheckList
						list={[
							'Team access',
							'ACLs & enhanced security',
							'Editable mappings',
							'1 Search Preview profile',
							'7-days analytics retention',
						]}
					/>
					<div className={listCaption}>Support and Guidance</div>
					<CheckList
						list={[
							'Email support',
							'Basic tooling support',
							'Premium support can be added',
						]}
					/>
				</NewPricingCard>
				<NewPricingCard
					style={{ backgroundColor: theme.badge.darkBlue }}
					name="Growth"
					price="$89"
					renderLink={price => (
						<Stripe
							name="Appbase.io Growth Plan"
							amount={price * 100}
							token={token => handleToken(token, 'growth-monthly')}
							stripeKey={stripeKey}
						>
							<span className={linkStyle}>Upgrade</span>
						</Stripe>
					)}
					pricingList={['1M Records', '10M API Calls / mo', '50 Queries / sec']}
				>
					<div className={listCaption}>Features</div>
					<CheckList
						list={[
							'Team access',
							'ACLs & enhanced security',
							'Editable mappings',
							'3 Search Preview profiles',
							'30-days analytics retention',
							'Accounts & Analytics API access',
						]}
					/>
					<div className={listCaption}>Support and Guidance</div>
					<CheckList
						list={[
							'Email support',
							'Priority tooling support',
							'Premium support can be added',
						]}
					/>
				</NewPricingCard>
			</div>
		</React.Fragment>
	);
};

export default Payment;
