import { API_ENDPOINT } from '../constants/config';

/**
 *
 * @param {object} data
 */
export default async function(data) {
	try {
		const res = await fetch(`${API_ENDPOINT}/import/verify`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		const json = await res.json();
		return json;
	} catch (error) {
		throw error;
	}
}
