import { notification } from 'antd';
import { getPermissions } from '../api/get-permissions';
import getPlan from '../api/get-plan';
import { SCALR_URL } from '../constants/config';
import getSettings from '../api/get-settings';

export default async function applySettings(app) {
	let paidApp = null;
	try {
		paidApp = await getPlan(app);
	} catch (e) {
		console.error(e);
	}

	const currentTierValue = paidApp && paidApp.tier;
	const isFree = currentTierValue === 'free';
	try {
		const permissions = await getPermissions(app);

		const writeKey = permissions.data.find(item => item.write === true);
		if (writeKey) {
			const { username, password } = writeKey;
			const uri = `https://${username}:${password}@${SCALR_URL}`;
			const destinationUri = `${uri}/${app}`;
			const settings = await getSettings(uri, app);
			const esVersion =
				settings &&
				settings[app] &&
				settings[app].settings &&
				settings[app].settings.index &&
				settings[app].settings.index.version &&
				settings[app].settings.index.version.created[0];

			return {
				index: app,
				type: 'AppbaseApp',
				indexType: 'existing',
				uri: destinationUri,
				tier: isFree ? 'free' : 'paid',
				url: uri,
				es_version: esVersion,
			};
		}
	} catch (e) {
		notification.error({ message: e.message });
		return null;
	}
}
