import ImportOutline from '@ant-design/icons/lib/outline/ImportOutline';
import SwapOutline from '@ant-design/icons/lib/outline/SwapOutline';
import InboxOutline from '@ant-design/icons/lib/outline/InboxOutline';
import DownOutline from '@ant-design/icons/lib/outline/DownOutline';
import RightOutline from '@ant-design/icons/lib/outline/RightOutline';
import LeftOutline from '@ant-design/icons/lib/outline/LeftOutline';
import GithubOutline from '@ant-design/icons/lib/outline/GithubOutline';
import GitlabOutline from '@ant-design/icons/lib/outline/GitlabOutline';
import GoogleOutline from '@ant-design/icons/lib/outline/GoogleOutline';
import CheckCircleOutline from '@ant-design/icons/lib/outline/CheckCircleOutline';
import CloseCircleOutline from '@ant-design/icons/lib/outline/CloseCircleOutline';
import LoadingOutline from '@ant-design/icons/lib/outline/LoadingOutline';
import InfoCircleOutline from '@ant-design/icons/lib/outline/InfoCircleOutline';
import CheckOutline from '@ant-design/icons/lib/outline/CheckOutline';
import UpOutline from '@ant-design/icons/lib/outline/UpOutline';
import QuestionOutline from '@ant-design/icons/lib/outline/QuestionOutline';
import CloseOutline from '@ant-design/icons/lib/outline/CloseOutline';

export {
	ImportOutline,
	SwapOutline,
	InboxOutline,
	RightOutline,
	DownOutline,
	LeftOutline,
	GithubOutline,
	GitlabOutline,
	GoogleOutline,
	CheckCircleOutline,
	CloseCircleOutline,
	LoadingOutline,
	InfoCircleOutline,
	CheckOutline,
	UpOutline,
	QuestionOutline,
	CloseOutline,
};
