import Appbase from 'appbase-js';
import getSettings from './get-settings';

/**
 *
 * @param {object} data
 */
export default async function(uri, fetchFrom) {
	try {
		const response = await fetch(fetchFrom);
		const dataArray = await response.json();
		const url = new URL(uri);
		const index = {
			url: `${url.protocol}//${url.host}`,
			app: url.pathname.replace('/', ''),
			credentials: `${url.username}:${url.password}`,
		};
		const indexInfo = await getSettings(
			`${url.protocol}//${url.username}:${url.password}@${url.host}`,
			url.pathname.replace('/', ''),
		);

		const version =
			indexInfo &&
			indexInfo[index.app] &&
			indexInfo[index.app].settings &&
			indexInfo[index.app].settings.index &&
			indexInfo[index.app].settings.index.version &&
			indexInfo[index.app].settings.index.version.created
				? indexInfo[index.app].settings.index.version.created[0]
				: 5;

		const appbaseRef = Appbase(index);
		const requestBody = [];
		dataArray.slice(0, 2000).forEach(data => {
			// Pushing the operation and _id for each request
			requestBody.push({
				index: {
					_id: data.uniq_id,
				},
			});
			requestBody.push(data);
		});
		const bulkResponse = await appbaseRef.bulk({
			body: requestBody,
			type: +version === 5 ? index.app : '_doc',
		});

		return bulkResponse;
	} catch (error) {
		throw error;
	}
}
