import { ACC_API } from '../constants/config';

export default async function(clusterId) {
	try {
		const res = await fetch(`${ACC_API}/v1/_status/${clusterId}`, {
			method: 'GET',
			credentials: 'include',
		});

		const data = await res.json();
		if (res.status >= 400) {
			throw new Error(data.status.message);
		}

		return data;
	} catch (error) {
		throw error;
	}
}
