import URLSearchParams from 'url-search-params';
import urlParser from 'url-parser-lite';
import { ACC_API } from '../constants/config';

/**
 *
 * @param {string} searchParam
 */

export const getSearchParams = search => {
	const paramInstance = new URLSearchParams(search);
	const searchParams = {};

	paramInstance.forEach((value, key) => {
		searchParams[key] = value;
	});

	return searchParams;
};
export async function createUserIfNew(idToken) {
	const response = await fetch(`${ACC_API}/user/signup-if-new-user`, {
		body: JSON.stringify({
			idToken,
		}),
		method: 'POST',
	});

	if (response.status === 200) {
		return response;
	}
	console.log(response);
	return response;
}

export const getUser = async () => {
	const response = await fetch(`${ACC_API}/user`, { credentials: 'include' });
	const data = await response.json();

	if (
		(response.status === 401 || response.status === 403) &&
		data.action &&
		data.action.email_verification
	) {
		return data;
	}
	if (response.status >= 400) {
		throw new Error(data.message);
	}
	const user = {
		...data.body,
		...data.body.details,
	};

	const { apps } = data.body;

	window.Intercom('boot', {
		app_id: 'f9514ssx',
		custom_launcher_selector: '#intercom',
		email: user.email,
		name: user.name,
	});

	return { user, apps };
};

/**
 *
 * @param {string} provider
 * Redirects to login page and after successful authentication returns back to the next URL
 */
export const getLoginURL = provider =>
	`${ACC_API}/login/${provider}?next=${window.location.origin}`;

/**
 *
 * Redirects to Logout page and after successful logout returns back to the next URL.
 * You need to redirect the page manually, for eg: window.location.href = logoutURL.
 */
export const logoutURL = `${ACC_API}/logout?next=${window.location.origin}`;

/**
 *
 * @param {string} url
 */
export const parseUrl = url => {
	if (!url) {
		return {
			credentials: null,
			url: null,
		};
	}
	const { auth } = urlParser(url);
	const filteredUrl = auth ? url.replace(`${auth}@`, '') : url;
	return {
		credentials: auth,
		url: filteredUrl,
	};
};

/**
 *
 * @param {string} url
 * @param {object} otherHeaders
 */
export const getESHeaders = (url, otherHeaders = {}) => {
	const { credentials } = parseUrl(url);
	const headers = {
		'Content-Type': 'application/json',
	};

	if (credentials) {
		headers.Authorization = `Basic ${btoa(credentials)}`;
	}

	return {
		...headers,
		...otherHeaders,
	};
};

export const sourceStorageValue = () => {
	const source = localStorage.getItem('source');
	localStorage.removeItem('source');
	if (source && source !== 'undefined') {
		return source;
	}
	return false;
};

export const validateAppName = name => {
	const symbolsToCheck = /[\s#&*'"\\|,<>\/?]/; //eslint-disable-line
	const nameCharacters = name.split('');
	const startsWith =
		nameCharacters[0] === '+' || nameCharacters[0] === '-' || nameCharacters[0] === '_';

	if (name === '.' || name === '..' || name === '' || symbolsToCheck.test(name) || startsWith) {
		return false;
	}
	return true;
};

export const validationsList = [
	'Lowercase only',
	'Cannot include \\, /, *, ?, ", <, >, |, ` ` (space character), ,, #',
	'Cannot start with -, _, +',
	'Cannot be . or ..',
];

export const formatBytes = (bytes, decimals) => {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	// eslint-disable-next-line no-restricted-properties
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const showPaidWall = ({ currentPlan, size }) => {
	if (currentPlan === 'free' && size > 1024 ** 2 * 10) {
		return true;
	}
	return false;
};

export const isEmptyObject = obj => {
	if (obj === null) return true;
	if (!Object.keys(obj).length) return true;
	return false;
};

export const injectAuthTokenHeaderIntoFetchGlobally = () => {
	const { fetch: originalFetch } = window;

	window.fetch = async (...args) => {
		const [resource, config = {}] = args;
		const accessToken = window.localStorage.getItem('AUTH_0_ACCESS_TOKEN');

		if (accessToken) {
			if (!config.headers) {
				config.headers = {};
			}
			if (!config.headers.Authorization) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}
		}
		// request interceptor here
		const response = await originalFetch(resource, config);
		// response interceptor here
		return response;
	};
};

export function parseJwt(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(c => {
				return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
			})
			.join(''),
	);

	return JSON.parse(jsonPayload);
}
