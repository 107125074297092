import React, { Suspense } from 'react';
import { string, array, object } from 'prop-types';

const getDestinationComponent = file => ({
	DestinationComponent: React.lazy(() => import(`./${file}`)),
});

const SelectDestination = ({ selectedValue, apps, destinationContext }) => {
	const { DestinationComponent } = getDestinationComponent(selectedValue);
	return (
		<Suspense fallback="">
			<DestinationComponent apps={apps} destinationContext={destinationContext} />
		</Suspense>
	);
};

SelectDestination.propTypes = {
	selectedValue: string.isRequired,
	apps: array.isRequired,
	destinationContext: object.isRequired,
};

export default SelectDestination;
