import React, { Component } from 'react';
import { array, node, string, object, func } from 'prop-types';
import { css } from 'emotion';
import ClickToShow from './ClickToShow';

const pricingCard = css`
	border-radius: 14px;
	width: 100%;
	text-align: left;
	padding: 20px;
	background-color: #ffffff;
	color: #ffffff;
	box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.05);
	@media (max-width: 1024px) {
		max-width: 45%;
	}
	@media (max-width: 576px) {
		max-width: 80%;
	}
`;

const pricingCardHeader = css``;

const planName = css`
	font-size: 1rem;
	font-weight: 600;
	line-height: 28px;
	text-transform: uppercase;
`;

const priceWrapper = css`
	display: flex;
	align-items: center;
	font-size: 2rem;
	font-weight: 600;
	margin-bottom: 20px;
	> div {
		font-size: 0.875rem;
		opacity: 0.8;
		margin-left: 10px;
	}
`;

const pricingListStyle = css`
	list-style: none;
	padding: 0;
	margin: 10px 0;
	> li {
		display: flex;
		align-items: center;
		margin: 3px 0;
	}
`;

const NewPricingCard = ({ price, pricingList = [], name, renderLink, children, ...rest }) => {
	return (
		<div className={pricingCard} {...rest}>
			<div className={pricingCardHeader}>
				<div className={planName}>{name}</div>
				<div className={priceWrapper}>
					{price}
					<div>/month</div>
				</div>
				{renderLink(price)}
			</div>
			<div className={pricingListStyle} style={{ fontWeight: 600 }}>
				{pricingList.map(list => (
					<li key={list}>{list}</li>
				))}
			</div>
			{children && (
				<ClickToShow>
					<div
						className={pricingListStyle}
						style={{ fontSize: '0.875rem', fontWeight: 600 }}
					>
						{children}
					</div>
				</ClickToShow>
			)}
		</div>
	);
};

export default NewPricingCard;

NewPricingCard.propTypes = {
	price: string,
	pricingList: array,
	name: string,
	renderLink: func.isRequired,
	children: node,
};
