import { ACC_API } from '../constants/config';

export default async function() {
	try {
		const res = await fetch(`${ACC_API}/v1/clusters`, {
			method: 'GET',
			credentials: 'include',
		});

		const json = await res.json();
		if (res.status >= 400) {
			throw new Error(json.status.message);
		}
		return json;
	} catch (error) {
		throw error;
	}
}
