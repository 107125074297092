// Live Keyes and URLs

export const ACC_API = 'https://accapi.appbase.io';
export const SCALR_URL = 'scalr.api.appbase.io';

export const STRIPE_KEY = 'pk_live_ihb1fzO4h1ykymhpZsA3GaQR';

export const API_ENDPOINT = 'https://importer-server.appbase.io/api';

// Testing Keyes and URLs

// export const ACC_API = 'https://accapi-staging.reactiveapps.io';
// export const SCALR_URL = 'api-staging.reactiveapps.io';

// export const STRIPE_KEY = 'pk_test_DYtAxDRTg6cENksacX1zhE02';
