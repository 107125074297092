import { getESHeaders, parseUrl } from '../utils';
import { settings } from './put-settings';

export default async function createClusterIndex(url, app, appendSettings = false) {
	try {
		const { url: originalUrl } = parseUrl(url);
		const body = {};
		if (appendSettings) {
			body.settings = settings;
		}
		const res = await fetch(`${originalUrl}/${app}`, {
			method: 'PUT',
			headers: getESHeaders(url),
			body: JSON.stringify(body),
		});

		if (res.status >= 400) {
			throw new Error();
		}

		const json = await res.json();
		return { success: json.acknowledged };
	} catch (error) {
		throw error;
	}
}
