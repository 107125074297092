import { USER } from '../constants';

export const initialUser = {
	isLoading: false,
	data: null,
	error: null,
};

function userReducer(state, action) {
	switch (action.type) {
		case USER.LOAD: {
			return {
				isLoading: true,
				data: null,
				error: null,
			};
		}
		case USER.LOAD_SUCCESS: {
			const { picture: userPicture, avatar_url, ...rest } = action.payload;
			const picture = userPicture || avatar_url;
			return {
				isLoading: false,
				data: { picture, ...rest },
				error: null,
			};
		}
		case USER.LOAD_ERROR: {
			return {
				isLoading: false,
				data: null,
				error: action.error,
			};
		}
		default:
			return state;
	}
}

export default userReducer;
