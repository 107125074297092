export const USER = {
	LOAD: 'USER_LOAD',
	LOAD_ERROR: 'USER_ERROR',
	LOAD_SUCCESS: 'USER_SUCCESS',
};

export const SOURCE = {
	UPDATE: 'UPDATE_SOURCE',
	RESET: 'RESET_SOURCE',
};

export const DESTINATION = {
	UPDATE: 'UPDATE_DESTINATION',
	RESET: 'RESET_DESTINATION',
};
