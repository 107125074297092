import getSettings from './get-settings';
import closeCluster from './close-cluster';
import openCluster from './open-cluster';
import putSettings from './put-settings';
import createClusterIndex from './create-cluster-index';

const checkResponse = response => {
	if (response.status >= 400) {
		throw new Error(response.message);
	}
};

export const updateSettings = async (url, app, version, isCluster, isSelfHosted = false) => {
	let settings = await getSettings(url, app);

	// For self hosted clusters we need to add settings at time of index creation
	if (isSelfHosted) {
		if (settings.status === 404 && isCluster) {
			try {
				await createClusterIndex(url, app, isSelfHosted);
				return {
					isUpdated: true,
				};
			} catch (e) {
				return e;
			}
		}

		throw new Error('Index with the same name already exists. Try creating a new index.');
	}

	if (settings.status === 404 && isCluster) {
		try {
			await createClusterIndex(url, app);
			settings = await getSettings(url, app);
		} catch (e) {
			return e;
		}
	}

	const analyzers =
		settings[app] &&
		settings[app].index &&
		settings[app].index.analysis &&
		settings[app].index.analysis.analyzers;
	try {
		if (analyzers) {
			return { isUpdated: true };
		}

		const clusterResponse = await closeCluster(url, app);
		const settingsResponse = await putSettings(url, app);
		const openResponse = await openCluster(url, app);

		checkResponse(clusterResponse);
		checkResponse(settingsResponse);
		checkResponse(openResponse);

		return {
			isUpdated: true,
		};
	} catch (error) {
		await openCluster(url, app);
		throw error;
	}
};
