import { getESHeaders, parseUrl } from '../utils';

/**
 *
 * @param {string} url
 * @param {string} app
 */
export default async function(url, app) {
	try {
		const { url: originalUrl } = parseUrl(url);
		const res = await fetch(`${originalUrl}/${app}/_close`, {
			method: 'POST',
			headers: getESHeaders(url),
		});

		const json = await res.json();
		return json;
	} catch (error) {
		throw error;
	}
}
