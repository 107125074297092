import { DESTINATION } from '../constants';

export const initialDestination = null;

function destinationReducer(state, action) {
	switch (action.type) {
		case DESTINATION.UPDATE: {
			localStorage.removeItem('source');
			return {
				...action.payload,
			};
		}
		case DESTINATION.RESET: {
			return initialDestination;
		}
		default:
			return state;
	}
}

export default destinationReducer;
