import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Dropdown, Menu, message, notification } from 'antd';
import { css } from 'emotion';

const helpIcon = css`
	i {
		font-size: 22px !important;
		position: relative;
		top: 2px;
	}
`;

const heading = css`
	margin: 4px auto;
	font-weight: 600;
	line-height: 16px;
	font-size: 16px;
`;

const subHeading = css`
	margin: -2px auto 2px;
	font-size: 13px;
	color: #777;
`;

class HelpButton extends React.Component {
	handleClick = e => {
		const { key } = e;
		switch (key) {
			case 'chat': {
				window.Intercom('show');
				break;
			}
			case 'support':
				window.open('https://appbase.io/pricing/#support', '_blank');
				break;
			case 'twitter':
				window.open('https://twitter.com/appbaseio', '_blank');
				break;
			case 'updates':
				window.open('https://appbase.io/', '_blank');
				break;
			case 'privacy':
				window.open('https://appbase.io/privacy/', '_blank');
				break;
			default:
		}
	};

	render() {
		const menu = (
			<Menu onClick={this.handleClick}>
				<Menu.Item key="chat" style={{ padding: '10px 15px' }} className="open_intercom">
					<h3 className={heading}>
						Ask us anything!{' '}
						<span role="img" aria-label="Wave">
							👋
						</span>
					</h3>
					<p className={subHeading}>We reply to every issue.</p>
				</Menu.Item>
				<Menu.Item key="support">
					<h3 className={heading}>Get Support!</h3>
				</Menu.Item>
				<Menu.Item key="twitter">
					<p className={subHeading}>@appbaseio - Twitter</p>
				</Menu.Item>
				<Menu.Item key="privacy">
					<p className={subHeading}>Terms & Privacy</p>
				</Menu.Item>
			</Menu>
		);
		return (
			<React.Fragment>
				<Dropdown overlay={menu} trigger={['click']} placement="topLeft">
					<Button
						className={helpIcon}
						type="primary"
						size="large"
						shape="circle"
						icon="question"
					/>
				</Dropdown>
			</React.Fragment>
		);
	}
}

const HelpChat = props =>
	ReactDOM.createPortal(<HelpButton {...props} />, document.getElementById('help'));

export default HelpChat;
