import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import AppWrapper from './AppWrapper';

ReactDOM.render(
	<Auth0Provider
		domain="reactivesearch-cloud.us.auth0.com"
		clientId="6PKBL9lJKxj8mQWqEcU8gvudaC3YK1Vm"
		authorizationParams={{
			audience: 'https://reactivesearch-cloud.us.auth0.com/api/v2/',
			scope: 'read:current_user update:current_user_metadata',
			redirect_uri: window.location.origin,
		}}
	>
		<AppWrapper />
	</Auth0Provider>,
	document.getElementById('root'),
);
